import { WysiwygProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchor,
	heading,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<WysiwygProps> = {
	schemaType: "module",
	component: "Wysiwyg",
	category: "content",
	displayName: "Wysiwyg",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading },

				{
					type: "RichText",
					title: "Subtitle",
					key: "subtitle",
					placeholder: "Type a subtitle",
					hideable: true,
					isMockup: true,
					humanReadable: true,
				},

				{
					type: "Wysiwyg",
					title: "Wysiwyg Content",
					key: "wysiwyg",
					placeholder: "Type or add visual content",
					hideable: true,
					isMockup: true,
					mandatory: true,
					humanReadable: true,
				},

				{
					type: "ComponentContainer",
					title: "Link",
					key: "link",
					whiteList: ["Button"],
					hideable: true,
				},
			],
		},

		{
			title: "config",
			fields: [{ ...anchor }, { ...themeSelector }, ...verticalSpacing],
		},
	],

	default: {
		component: "Wysiwyg",
		title: {
			content: "Lorem ipsum",
			tag: "h2",
		},
		subtitle: "Lorem ipsum paragraph",
		topSpacing: "large",
		bottomSpacing: "large",
		subtheme: "default",
		wysiwyg: "# Lorem ipsum",
		link: {
			component: "Button",
			link: {
				linkType: "url",
				text: "Link",
				url: {
					linkToURL: "https://www.secuoyas.com",
				},
			},
		},
	},

	thumbnails: {
		"1x": "/thumbnails/modules/Wysiwyg/thumbnail@1x.png",
		"2x": "/thumbnails/modules/Wysiwyg/thumbnail@2x.png",
	},
};

export default schema;
