import { BasicContentProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchor,
	heading,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<BasicContentProps> = {
	schemaType: "module",
	component: "BasicContent",
	category: "content",
	displayName: "BasicContent",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ComponentContainer",
					title: "Visual content",
					whiteList: ["Image", "Video"],
					key: "visualContent",
					mandatory: true,
					helptext: "Recommended minimum image size: 792x514",
					hideable: true,
				},

				{ ...heading },

				{
					type: "RichText",
					title: "Subtitle",
					key: "subtitle",
					placeholder: "Type a subtitle",
					hideable: true,
					isMockup: true,
					humanReadable: true,
				},

				{
					type: "RichText",
					title: "Intro",
					key: "intro",
					placeholder: "Type an intro",
					hideable: true,
					isMockup: true,
					humanReadable: true,
				},

				{
					type: "RichText",
					title: "Text Content",
					key: "textContent",
					placeholder: "Type a content",
					hideable: true,
					isMockup: true,
					humanReadable: true,
				},

				{
					type: "ComponentContainer",
					title: "Link",
					key: "link",
					whiteList: ["Button"],
					hideable: true,
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchor },
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "layout1",
							img: "/thumbnails/modules/BasicContent/layouts/layout1.png",
						},
						{
							value: "layout2",
							img: "/thumbnails/modules/BasicContent/layouts/layout2.png",
						},
						{
							value: "layout3",
							img: "/thumbnails/modules/BasicContent/layouts/layout3.png",
						},
						{
							value: "layout4",
							img: "/thumbnails/modules/BasicContent/layouts/layout4.png",
						},
					],
				},
				{ ...themeSelector },
				...verticalSpacing,
			],
		},
	],

	default: {
		component: "BasicContent",
		visualContent: {
			image: {
				component: "Image",
			},
			video: {
				component: "Video",
			},
		},
		title: {
			content: "Lorem ipsum",
			tag: "h2",
		},
		subtitle: "Lorem ipsum paragraph",
		intro: "Lorem ipsum paragraph",
		textContent: "Lorem ipsum paragraph",
		link: {
			component: "Button",
			link: { text: "Saber más", linkType: "url" },
		},
		topSpacing: "large",
		bottomSpacing: "large",
		layout: "layout1",
		subtheme: "default",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/BasicContent/thumbnail@1x.png",
		"2x": "/thumbnails/modules/BasicContent/thumbnail@2x.png",
	},
};

export default schema;
