import { BasicTextProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchor,
	heading,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<BasicTextProps> = {
	schemaType: "module",
	component: "BasicText",
	category: "content",
	displayName: "Basic Text",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading },

				{
					type: "RichText",
					title: "Subtitle",
					key: "subtitle",
					placeholder: "Type a subtitle",
					hideable: true,
					isMockup: true,
					humanReadable: true,
				},

				{
					type: "FieldGroup",
					title: "Column 1",
					key: "column1",
					fields: [
						{
							type: "RichText",
							title: "Intro",
							key: "intro1",
							placeholder: "Type a lead text",
							hideable: true,
							isMockup: true,
							helptext:
								"Use double curly brackets to color words. For example: Lorem {{ipsum}}",
							humanReadable: true,
						},

						{
							type: "Wysiwyg",
							title: "Content",
							key: "content1",
							placeholder: "Type a content",
							hideable: true,
							isMockup: true,
							humanReadable: true,
						},

						{
							type: "ComponentContainer",
							title: "Link",
							key: "link1",
							whiteList: ["Button"],
							hideable: true,
						},
					],
				},

				{
					type: "FieldGroup",
					title: "Column 2",
					key: "column2",
					fields: [
						{
							type: "RichText",
							title: "Intro",
							key: "intro2",
							placeholder: "Type a lead text",
							hideable: true,
							isMockup: true,
							helptext:
								"Use double curly brackets to color words. For example: Lorem {{ipsum}}",
							humanReadable: true,
						},

						{
							type: "Wysiwyg",
							title: "Content",
							key: "content2",
							placeholder: "Type a content",
							hideable: true,
							isMockup: true,
							humanReadable: true,
						},

						{
							type: "ComponentContainer",
							title: "Link",
							key: "link2",
							whiteList: ["Button"],
							hideable: true,
						},
					],
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchor },
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "layout1",
							img: "/thumbnails/modules/BasicText/layouts/layout1.png",
						},
						{
							value: "layout2",
							img: "/thumbnails/modules/BasicText/layouts/layout2.png",
						},
						{
							value: "layout3",
							img: "/thumbnails/modules/BasicText/layouts/layout3.png",
						},
						{
							value: "layout4",
							img: "/thumbnails/modules/BasicText/layouts/layout4.png",
						},
					],
				},
				{ ...themeSelector },
				...verticalSpacing,
			],
		},
	],

	default: {
		component: "BasicText",
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		subtitle: "Lorem ipsum paragraph",
		intro1: "Lorem ipsum paragraph",
		content1: "Lorem ipsum paragraph",
		link1: {
			component: "Button",
			link: { text: "Saber más", linkType: "url" },
		},
		intro2: "Lorem ipsum paragraph",
		content2: "Lorem ipsum paragraph",
		link2: {
			component: "Button",
			link: { text: "Saber más", linkType: "url" },
		},
		topSpacing: "large",
		bottomSpacing: "large",
		layout: "layout1",
		subtheme: "default",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/BasicText/thumbnail@1x.png",
		"2x": "/thumbnails/modules/BasicText/thumbnail@2x.png",
	},
};

export default schema;
