import { CardCollectionProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchor,
	heading,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<CardCollectionProps> = {
	schemaType: "module",
	component: "CardCollection",
	category: "collection",
	displayName: "CardCollection",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading },

				{
					type: "RichText",
					title: "Content",
					key: "content",
					placeholder: "Type a content",
					hideable: true,
					isMockup: true,
					humanReadable: true,
				},

				{
					type: "ComponentArray",
					title: "Cards",
					key: "cards",
					mandatory: true,
					contentType: "components",
					whiteList: [
						"BasicCard",
						"ImageCard",
						"AccordionCard",
						"TextCard",
						//
					],
				},

				{
					type: "ComponentContainer",
					title: "Link",
					key: "link",
					whiteList: ["Button"],
					hideable: true,
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchor },

				{
					type: "UniqueCheck",
					title: "Fit layout",
					key: "fit",
					options: [{ title: "yes" }, { title: "no" }],
				},

				{
					type: "Select",
					title: "Card size",
					key: "cardSize",
					helptext: "The card size set the aspect-ratio",
					options: [
						{ label: "xs", value: "xs" },
						{ label: "sm", value: "sm" },
						{ label: "md", value: "md" },
						{ label: "lg", value: "lg" },
						{ label: "xl", value: "xl" },
					],
				},

				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 3,
					mandatory: true,
					options: [
						// Layout 1 columna
						{
							value: "layout1",
							img: "/thumbnails/modules/CardCollection/layouts/layout1.png",
						},
						// Layout 2 columnas M
						{
							value: "layout2",
							img: "/thumbnails/modules/CardCollection/layouts/layout2.png",
						},
					],
				},

				{ ...themeSelector },

				{
					title: "Card Style",
					key: "kind",
					type: "VisualUniqueSelection",
					elementUniqueSelection: true,
					reference: "cards",
					mandatory: true,
					options: [
						{
							value: "BasicCard",
							img: "/thumbnails/modules/CardCollection/card-styles/basic-card.png",
						},
						{
							value: "AccordionCard",
							img: "/thumbnails/modules/CardCollection/card-styles/accordion-card.png",
						},
						{
							value: "ImageCard",
							img: "/thumbnails/modules/CardCollection/card-styles/image-card.png",
						},
						{
							value: "TextCard",
							img: "/thumbnails/modules/CardCollection/card-styles/text-card.png",
						},
					],
					columns: 3,
				},

				...verticalSpacing,
			],
		},
	],

	default: {
		component: "CardCollection",
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		content: "Lorem ipsum paragraph",
		cardSize: "xs",
		kind: "BasicCard",
		cards: [
			{
				component: "BasicCard",
				content: "Content text",
				media: {
					fullImage: {
						component: "FullImage",
					},
					video: {
						component: "Video",
					},
				},
				link: {
					component: "Button",
					variant: "primary",
				},
			},
		],
		link: {
			component: "Button",
			variant: "primary",
			link: { text: "Ver más", linkType: "url" },
		},
		topSpacing: "medium",
		bottomSpacing: "medium",
		layout: "layout1",
		subtheme: "default",
		fit: false,
	},

	thumbnails: {
		"1x": "/thumbnails/modules/CardCollection/thumbnail@1x.png",
		"2x": "/thumbnails/modules/CardCollection/thumbnail@2x.png",
	},
};

export default schema;
