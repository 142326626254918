import { ShowcaseProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { anchor, themeSelector, verticalSpacing } from "@schemas/presets";

const schema: Schema.Module<ShowcaseProps> = {
	schemaType: "module",
	component: "Showcase",
	displayName: "Showcase",
	category: "content",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "RichText",
					title: "Title 01",
					key: "title1",
					isMockup: true,
					mandatory: false,
					placeholder: "Type a title",
					hideable: true,
					humanReadable: true,
				},

				{
					type: "RichText",
					title: "Title 02",
					key: "title2",
					isMockup: true,
					mandatory: false,
					placeholder: "Type a title",
					hideable: true,
					humanReadable: true,
				},

				{
					type: "RichText",
					title: "Text Content",
					key: "content",
					placeholder: "Type a content",
					hideable: true,
					isMockup: true,
					humanReadable: true,
				},

				{
					type: "ComponentContainer",
					title: "Link",
					key: "link",
					whiteList: ["Button"],
					hideable: true,
				},

				{
					type: "ComponentArray",
					title: "Images",
					key: "images",
					whiteList: ["Image"],
					contentType: "components",
					maxItems: 16,
					mandatory: true,
				},
			],
		},

		{
			title: "config",
			fields: [{ ...anchor }, { ...themeSelector }, ...verticalSpacing],
		},
	],

	default: {
		component: "Showcase",
		title1: "Lorem ipsum",
		title2: "Lorem ipsum",
		content: "Lorem ipsum paragraph",
		link: {
			component: "Button",
			link: { text: "Saber más", linkType: "url" },
		},
		images: [
			{ component: "Image" },
			{ component: "Image" },
			{ component: "Image" },
		],
		subtheme: "default",
		topSpacing: "large",
		bottomSpacing: "large",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/Showcase/thumbnail@1x.png",
		"2x": "/thumbnails/modules/Showcase/thumbnail@2x.png",
	},
};

export default schema;
