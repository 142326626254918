import { CypherCollectionProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchor,
	heading,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<CypherCollectionProps> = {
	schemaType: "module",
	component: "CypherCollection",
	category: "collection",
	displayName: "CypherCollection",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading },

				{
					type: "ComponentArray",
					title: "Cards",
					key: "cards",
					mandatory: true,
					contentType: "components",
					whiteList: ["CypherCard"],
					maxItems: 4,
				},

				{
					type: "ComponentContainer",
					title: "Link",
					key: "link",
					whiteList: ["Button"],
					hideable: true,
				},
			],
		},

		{
			title: "config",
			fields: [{ ...anchor }, { ...themeSelector }, ...verticalSpacing],
		},
	],

	default: {
		component: "CypherCollection",
		title: {
			content: "Lorem ipsum",
			tag: "h2",
		},
		cards: [],
		link: {
			component: "Button",
			link: { text: "Saber más", linkType: "url" },
		},
		topSpacing: "large",
		bottomSpacing: "large",
		subtheme: "default",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/CypherCollection/thumbnail@1x.png",
		"2x": "/thumbnails/modules/CypherCollection/thumbnail@2x.png",
	},
};

export default schema;
